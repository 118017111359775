import './App.css';
import {Routes, Route} from "react-router-dom";
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import EULA from './pages/EULA';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/eula-terms-of-use" element={<EULA />} />
    </Routes>
  );
}

export default App;