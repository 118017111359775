import styles from "../styles/Privacy.module.css";
import { useRef, useState, useEffect, useMemo } from "react";

const darkThemeColors = {
    primaryColor: "rgba(20,20,30,1)",
    secondaryColor: "#fff",
    tertiaryColor: "rgba(255,0,70,1)",
    quaternaryColor: "rgba(40, 40, 50, 1)",
    quinaryColor: "rgba(10, 10, 10, 1)",
}

const lightThemeColors = {
    primaryColor: "#fff",
    secondaryColor: "#000",
    tertiaryColor: "rgba(255,0,70,1)",
    quaternaryColor: "rgba(200, 200, 200, 1)",
    quinaryColor: "rgba(200, 200, 200, 1)",
}

function setLightThemeRoot() {
    document.documentElement.style.setProperty("--primary-color", lightThemeColors.primaryColor);
    document.documentElement.style.setProperty("--secondary-color", lightThemeColors.secondaryColor);
    document.documentElement.style.setProperty("--tertiary-color", lightThemeColors.tertiaryColor);
    document.documentElement.style.setProperty("--quaternary-color", lightThemeColors.quaternaryColor);
    document.documentElement.style.setProperty("--quinary-color", lightThemeColors.quinaryColor);
}

function setDarkThemeRoot() {
    document.body.classList.add("dark-theme");
    document.documentElement.style.setProperty("--primary-color", darkThemeColors.primaryColor);
    document.documentElement.style.setProperty("--secondary-color", darkThemeColors.secondaryColor);
    document.documentElement.style.setProperty("--tertiary-color", darkThemeColors.tertiaryColor);
    document.documentElement.style.setProperty("--quaternary-color", darkThemeColors.quaternaryColor);
    document.documentElement.style.setProperty("--quinary-color", darkThemeColors.quinaryColor);
}

function generateFormattedString() {
    const characters = '0123456789abcdef';
    let formattedString = '';

    for (let i = 0; i < 32; i++) {
        formattedString += characters[Math.floor(Math.random() * characters.length)];

        if (i === 7 || i === 11 || i === 15 || i === 19) {
            formattedString += '-';
        }
    }

    return formattedString;
}

const splitMessageIntoChunks = async (message, chunkSize, firstException = null) => {
    let chunks = [];
    let start = 0;
    let count = 0;

    while (start < message.length) {
        let chunk = message.slice(start, start + chunkSize);
        if (firstException && count === 0) {
            chunk = message.slice(start, start + firstException);
        }
        chunks.push(chunk);
        start += chunkSize;
        count += 1;
    }

    return chunks;
};

export default function EULA() {
    const [auth, setAuth] = useState(null);
    const inputRef = useRef(null);
    const keyRef = useRef(null);
    const [statusText, setStatusText] = useState("");
    const [statusColor, setStatusColor] = useState("rgba(0,0,0,0)");
    const [toSummarizeYtAuthor, setToSummarizeYtAuthor] = useState("");
    const [toSummarizeYtTitle, setToSummarizeYtTitle] = useState("");
    const [toSummarizeYtThumbnail, setToSummarizeYtThumbnail] = useState("");
    const [toSummarizeYtLength, setToSummarizeYtLength] = useState("");
    const [toSummarizeYtId, setToSummarizeYtId] = useState("");
    const [messagesDisplay, setMessagesDisplay] = useState([]);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [transcript, setTranscript] = useState(false);
    const [loadingSummary, setLoadingSummary] = useState(false);
    const [theme, setTheme] = useState("light");
    const [writeSummary, setWriteSummary] = useState(false);
    const [languageSelected, setLanguageSelected] = useState({ language: "English", flag: "🇬🇧" });
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [temperature, setTemperature] = useState(0);
    const [model, setModel] = useState("gpt-3.5-turbo");
    const [prompt, setPrompt] = useState("");
    const promptRef = useRef(null);
    const [chunkSize, setChunkSize] = useState(10000);

    let stopRequested = false;

    useEffect(() => {
        if (writeSummary) {
            const filteredItems = messagesDisplay.filter(item => item.from === 'assistant');
            const accumulatedText = filteredItems.reduce((acc, item) => acc + item.message, '');

            const video_id = toSummarizeYtId;
            const summary_text = accumulatedText;
            const video_title = toSummarizeYtTitle;
            const video_author = toSummarizeYtAuthor;
            const summary_language = languageSelected;
            const user_os = "web";

            const response = fetch(`https://us-central1-vidsummize.cloudfunctions.net/writeNewSummaryWeb`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ video_id, summary_text, video_title, video_author, summary_language, user_os }),
            });

            setWriteSummary(false);
        }
    }, [writeSummary]);

    const convertSecondsToMinutesAndSeconds = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secondsLeft = seconds % 60;

        return `${minutes}:${secondsLeft < 10 ? "0" + secondsLeft : secondsLeft}`;
    }

    async function getVideoDetails(videoId) {
        const response = await fetch(`https://us-central1-vidsummize.cloudfunctions.net/getTranscript`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ video_id: videoId }),
        });

        const json = await response.json();

        return { transcript: json.transcript, videoDetails: json.videoDetails };
    }

    const extractVideoId = (url) => {
        let videoId;

        if (url.includes("youtu.be")) {
            videoId = url.split("youtu.be/")[1];
        } else {
            const regex = /(?<=v=)[\w-]+/;
            const match = url.match(regex);

            if (!match || !match[0]) {
                if (url.length < 11) {
                    return;
                }
                videoId = url;
            } else {
                videoId = match[0];
            }
        }

        return videoId;
    }

    useEffect(() => {
        const hasAuth = localStorage.getItem("vidsummize_auth");
        const theme = localStorage.getItem("vidsummize_theme");
        const language = localStorage.getItem("vidsummize_language");

        if (!hasAuth) {
            setAuth(false);
        } else {
            setAuth(hasAuth);
        }

        if (!theme) {
            localStorage.setItem("vidsummize_theme", "light");
        } else {
            if (theme === "dark") {
                setDarkThemeRoot();
                setTheme("dark");
            } else {
                setLightThemeRoot();
                setTheme("light");
            }
        }

        if (!language) {
            localStorage.setItem("vidsummize_language", JSON.stringify({ language: "English", flag: "🇬🇧" }));
            setPrompt(translations["perMessagePrompt"]["English"]);
        } else {
            setLanguageSelected(JSON.parse(language));
            setPrompt(translations["perMessagePrompt"][JSON.parse(language).language]);
        }
    }, [])

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (loadingSummary) return;
        const input = inputRef.current.value;
        const videoId = await extractVideoId(input);

        if (!videoId) {
            setStatusColor("rgba(255,0,70,1");
            setStatusText("Invalid URL");

            setTimeout(() => {
                setStatusColor("rgba(0,0,0,0)");
                setStatusText("");
            }, 3000);
            return;
        }

        setStatusColor("rgba(255,200,0,1)");
        setStatusText("Loading Transcript...")

        const { transcript, videoDetails } = await getVideoDetails(videoId);
        const { duration, channel, title, thumbnail, status } = videoDetails;

        if (!transcript) {
            setStatusColor("rgba(255,0,70,1");
            setStatusText("Cannot summarize. Video has no captions.");
            return;
        }

        setStatusColor("rgba(0,255,100,1");
        setStatusText("Fetched Transcript!");

        setToSummarizeYtAuthor(channel);
        setToSummarizeYtTitle(title);
        setToSummarizeYtThumbnail(thumbnail);
        setToSummarizeYtLength(duration);
        setToSummarizeYtId(videoId);
        setTranscript(transcript);
        setVideoLoaded(true);

        // inputRef.current.value = "";
    }

    const [supportedLanguages, setSupportedLanguages] = useState([
        {
            language: "English",
            flag: "🇬🇧",
            supported: true,
            limited: false,
        },
        {
            language: "Español",
            flag: "🇪🇸",
            supported: true,
            limited: false,
        },
        {
            language: "Pусский",
            flag: "🇷🇺",
            supported: true,
            limited: false,
        },
        {
            language: "Português",
            flag: "🇧🇷",
            supported: true,
            limited: false,
        },
        {
            language: "Deutsch",
            flag: "🇩🇪",
            supported: true,
            limited: false,
        },
        {
            language: "Français",
            flag: "🇫🇷",
            supported: true,
            limited: false,
        },
        {
            language: "Italiano",
            flag: "🇮🇹",
            supported: true,
            limited: false,
        },
        {
            language: "日本語",
            flag: "🇯🇵",
            supported: true,
            limited: true,
            limitedText: "限られた精度",
        },
        {
            language: "中文",
            flag: "🇨🇳",
            supported: true,
            limited: true,
            limitedText: "有限的精度",
        },
        {
            language: "العربية",
            flag: "🇸🇦",
            supported: true,
            limited: true,
            limitedText: "دقة محدودة",
        },
        {
            language: "हिंदी",
            flag: "🇮🇳",
            supported: true,
            limited: true,
            limitedText: "सीमित अनुभव",
        },
        {
            language: "한국어",
            flag: "🇰🇷",
            supported: true,
            limited: true,
            limitedText: "제한된 정확도",
        },
        {
            language: "اردو",
            flag: "🇵🇰",
            supported: true,
            limited: true,
            limitedText: "محدود درستگی",
        },
        {
            language: "Bahasa Melayu",
            flag: "🇲🇾",
            supported: true,
        },
        {
            language: "Bahasa Indonesia",
            flag: "🇮🇩",
            supported: true,
        },
        {
            language: "Tiếng Việt",
            flag: "🇻🇳",
            supported: true,
        },
        {
            language: "Nederlands",
            flag: "🇳🇱",
            supported: false,
        },
        {
            language: "Svenska",
            flag: "🇸🇪",
            supported: false,
        },
        {
            language: "Türkçe",
            flag: "🇹🇷",
            supported: false,
        },
        {
            language: "Українська",
            flag: "🇺🇦",
            supported: false,
        },
        {
            language: "Slovenský",
            flag: "🇸🇰",
            supported: true,
        },
        {
            language: "Polski",
            flag: "🇵🇱",
            supported: false,
        },
    ]);

    const [translations, setTranslations] = useState({
        perMessagePrompt: {
            "English": "summarize this text using bullet points, give it a big, bold heading as well. make it: easy to read and exhaustive but also provide context on topics or concepts the reader might not know of if necessary. remember that you heading should be separated by two new lines from the bullet points.",
            "Pусский": "Подведите итоги этого текста, используя маркеры для списка, дайте ему крупный и яркий заголовок. Сделайте его легко читаемым и исчерпывающим, но также предоставьте контекст по темам или концепциям, которые читатель может не знать, если это необходимо. Помните, что ваш заголовок должен отделяться двумя новыми строками от маркеров списка.",
            "Français": "Résumez ce texte en utilisant des puces, donnez-lui un grand titre en gras. Rendez-le facile à lire et exhaustif, mais fournissez également du contexte sur les sujets ou les concepts que le lecteur pourrait ne pas connaître si nécessaire. N'oubliez pas que votre titre doit être séparé de deux nouvelles lignes des puces.",
            "Deutsch": "Fassen Sie diesen Text mit Aufzählungspunkten zusammen, geben Sie ihm eine große, fette Überschrift. Machen Sie ihn leicht lesbar und umfassend, aber geben Sie auch Kontext zu Themen oder Konzepten, die der Leser möglicherweise nicht kennt, wenn nötig. Denken Sie daran, dass Ihre Überschrift durch zwei neue Zeilen von den Aufzählungspunkten getrennt sein sollte.",
            "Español": "Resuma este texto utilizando viñetas, déle un título grande y en negrita. Hágalo fácil de leer y exhaustivo, pero proporcione también contexto sobre temas o conceptos que el lector podría no conocer si es necesario. Recuerde que su título debe estar separado por dos nuevas líneas de las viñetas.",
            "Italiano": "Sommari questo testo utilizzando el elenco puntato, dando un grande titolo in grassetto. Rendilo facile da leggere ed esaustivo, ma fornisca anche contesto sui temi o sui concetti che il lettore potrebbe non conoscere, se necessario. Ricorda che il tuo titolo deve essere separato da due nuove linee dall'elenco puntato.",
            "Slovenský": "Zhrňte tento text pomocou odrážkového zoznamu, dajte mu veľký, tučný nadpis. Urobte ho ľahko čitateľným a podrobným, ale ak je to potrebné, poskytnite tiež kontext k témam alebo konceptom, ktoré by mohli byť pre čitateľa neznáme. Pamätajte si, že váš nadpis by mal byť oddelený dvoma novými riadkami od odrážkového zoznamu.",
            "中文": "用项目符号总结这个文本，同时加上一个大而醒目的标题。使其易于阅读和详尽，但如果必要，还要提供读者可能不了解的主题或概念的上下文。请记住，你的标题应与项目符号之间用两个新行分隔",
            "한국어": "글머리 기호를 사용하여이 텍스트를 요약하고 크고 굵은 제목을 제공합니다. 쉽게 읽고 철저하게 만드는 것뿐만 아니라 필요한 경우 독자가 모르는 주제나 개념에 대한 문맥도 제공합니다. 제목은 글머리 기호에서 두 개의 새 줄로 분리해야합니다.",
            "हिंदी": "बुलेट बिंदुओं का उपयोग करके इस पाठ का सारांश दें, उसे एक बड़ा, बोल्ड हेडिंग भी दें। यह आसान और विस्तृत बनाएँ, लेकिन आवश्यक होने पर पाठक को विषयों या अवधारणाओं के संदर्भ में भी प्रदान करें जो वह नहीं जानता हो सकता है। याद रखें कि आपका हेडिंग बुलेट बिंदुओं से दो नए लाइनों से अलग होना चाहिए।",
            "العربية": "قم بتلخيص هذا النص باستخدام علامات النقط، وتزويده بعنوان كبير وجريء. جعله سهل القراءة وشاملًا وكذلك توفير السياق اللازم للموضوعات أو المفاهيم التي قد لا يكون للقارئ معرفة بها إن لزم الأمر. تذكر أن يجب فصل العنوان بمسافتين من العلامات.",
            "اردو": "اس متن کو بلیٹ پوائنٹس کی مدد سے خلاصہ کریں، اسے بڑا، جریئہ عنوان بھی دیں۔ اسے آسان پڑھنے کے ساتھ مکمل بنائیں، لیکن ضرورت پڑنے پر قارئین کو موضوعات یا تصورات کے سیاق کے بارے میں بھی معلومات فراہم کریں جو وہ نہیں جانتے ہوسکتی ہیں۔ یاد رکھیں کہ آپ کا عنوان بلیٹ پوائنٹس سے دو نئی لائنز کے ذریعے الگ ہونا چاہئے۔",
            "Português": "Resuma este texto usando marcadores de pontos, dê um título grande e em negrito também. Torne-o fácil de ler e exaustivo, mas também forneça contexto sobre tópicos ou conceitos que o leitor possa não conhecer, se necessário. Lembre-se de que seu título deve ser separado por duas novas linhas dos marcadores de pontos.",
            "Indonesian": "Ringkas teks ini dengan menggunakan poin-poin, berikan judul yang besar dan tebal. Mudah dibaca dan komprehensif, tetapi juga berikan konteks pada topik atau konsep yang mungkin tidak diketahui pembaca jika perlu. Ingatlah bahwa judul harus dipisahkan oleh dua baris baru dari poin-poin.",
            "Bahasa Melayu": "Ringkaskan teks ini dengan menggunakan titik-titik, berikan tajuk yang besar dan tebal. Buat mudah dibaca dan menyeluruh, tetapi berikan konteks tentang topik atau konsep yang pembaca mungkin tidak tahu jika diperlukan. Ingat bahawa tajuk anda perlu dipisahkan dengan dua baris baru dari titik-titik.",
            "Bahasa Indonesia": "Ringkas teks ini dengan menggunakan poin-poin, berikan judul yang besar dan tebal. Mudah dibaca dan komprehensif, tetapi juga berikan konteks pada topik atau konsep yang mungkin tidak diketahui pembaca jika perlu. Ingatlah bahwa judul harus dipisahkan oleh dua baris baru dari poin-poin.",
            "Tiếng Việt": "Tóm tắt văn bản này bằng cách sử dụng các điểm đánh dấu, đưa ra tiêu đề lớn và đậm. Làm cho nó dễ đọc và toàn diện, nhưng cũng cung cấp ngữ cảnh về các chủ đề hoặc khái niệm mà độc giả có thể không biết nếu cần thiết. Nhớ rằng tiêu đề của bạn phải được phân tách bằng hai dòng mới từ các điểm đánh dấu.",
        },
        system: {
            "English": "ONLY ANSWER IN ENGLISH",

            // Spanish ONLY ANSWER IN SPANISH
            "Español": "SOLO RESPONDA EN ESPAÑOL",

            // Russian ONLY ANSWER IN RUSSIAN
            "Pусский": "ОТВЕТЬТЕ ТОЛЬКО НА РУССКОМ",

            // French ONLY ANSWER IN FRENCH
            "Français": "RÉPONDEZ UNIQUEMENT EN FRANÇAIS",

            // German ONLY ANSWER IN GERMAN
            "Deutsch": "ANTWORTEN SIE NUR AUF DEUTSCH",

            // Italian ONLY ANSWER IN ITALIAN
            "Italiano": "RISPOSTA SOLO IN ITALIANO",

            // Japanese ONLY ANSWER IN JAPANESE
            "日本語": "日本語でのみ回答してください",

            // Portuguese ONLY ANSWER IN PORTUGUESE
            "Português": "RESPOSTA APENAS EM PORTUGUÊS",

            // Chinese (Traditional) ONLY ANSWER IN CHINESE (TRADITIONAL)
            "中文": "只回答中文",

            // Hindi ONLY ANSWER IN HINDI
            "हिंदी": "हिंदी में ही जवाब दें",

            // Arabic ONLY ANSWER IN ARABIC
            "العربية": "الرد فقط باللغة العربية",

            // Korean ONLY ANSWER IN KOREAN
            "한국어": "한국어로만 답하세요",

            // Urdu ONLY ANSWER IN URDU
            "اردو": "صرفا اردو میں جواب دیں",

            // Vietnamese ONLY ANSWER IN VIETNAMESE
            "Tiếng Việt": "Chỉ trả lời bằng tiếng Việt",

            // Malay ONLY ANSWER IN MALAY
            "Bahasa Melayu": "Hanya jawab dalam Bahasa Melayu",

            // Indonesian ONLY ANSWER IN INDONESIAN
            "Bahasa Indonesia": "Hanya jawab dalam Bahasa Indonesia",

            // Slovak ONLY ANSWER IN SLOVAK slovensky
            "Slovenský": "ODPOVIEDAŤ LEN NA SLOVENSKY",

            // Vietnamese ONLY ANSWER IN VIETNAMESE
            "Tiếng Việt": "Chỉ trả lời bằng tiếng Việt",
        },
        summarizeButtonText: {
            "English": "Summarize", // English
            "Español": "Resumir", // Spanish
            "Pусский": "Итог", // Russian
            "Français": "Résumer.", // French
            "Deutsch": "Zusammenfassen", // German
            "Italiano": "Riassumi", // Italian
            "日本語": "要約する", // Japanese
            "Português": "Resumir", // Portuguese
            "中文": "总结", // Chinese (Traditional)
            "हिंदी": "सारांश", // Hindi
            "العربية": "ملخص", // Arabic
            "한국어": "요약", // Korean
            "اردو": "خلاصہ", // Urdu
            "Bahasa Melayu": "Ringkasan", // Malay
            "Bahasa Indonesia": "Ringkasan", // Indonesian
            "Tiếng Việt": "Tóm tắt", // Vietnamese
            "Slovenský": "Zhrnutie", // Slovak
        },
        summarizeButtonInUseText: {
            "English": "Wait until finished",
            "Español": "Esperar hasta que termine",
            "Pусский": "Ждите, пока закончится",
            "Français": "Attendre jusqu'à ce que cela soit terminé.",
            "Deutsch": "Warte bis es beendet ist",
            "Italiano": "Aspetta finché non è finito",
            "日本語": "終わるまで待つ",
            "Português": "Espere até terminar",
            "中文": "等到完成",
            "বাংলা": "শেষ হওয়ার অপেক্ষা করুন",
            "हिंदी": "समाप्त होने तक प्रतीक्षा करें",
            "한국어": "끝날 때까지 기다리십시오",
            "اردو": "ختم ہونے تک انتظار کریں",
            "العربية": "انتظر حتى ينتهي",
            "Bahasa Melayu": "Tunggu sehingga selesai",
            "Bahasa Indonesia": "Tunggu sampai selesai",
            "Tiếng Việt": "Đợi cho đến khi hoàn thành",
            "Slovenský": "Počkajte, kým sa dokončí",
        },
        chunkSizes: {
            "English": 15500,
            "Español": 14000,
            "Français": 14000,
            "Deutsch": 14000,
            "Italiano": 14000,
            "Pусский": 10000,
            "Português": 14000,
            "日本語": 14000, // Japanese
            "中文": 10000, // Chinese (Traditional)
            "हिंदी": 10000, // Hindi
            "العربية": 14000, // Arabic
            "한국어": 10000, // Korean
            "اردو": 10000,// Urdu
            "Bahasa Melayu": 10000, // Malay
            "Bahasa Indonesia": 10000, // Indonesian
            "Tiếng Việt": 10000, // Vietnamese
            "Slovenský": 10000, // Slovak
        },
        chapter: {
            "English": "Chapter", // English
            "Español": "Capítulo", // Spanish
            "Français": "Chapitre", // French
            "Deutsch": "Kapitel", // German
            "Italiano": "Capitolo", // Italian
            "Pусский": "Глава", // Russian     
            "Português": "Capítulo", // Portuguese
            "العربية": "الفصل", // Arabic
            "हिंदी": "अध्याय", // Hindi
            "日本語": "章", // Japanese
            "한국어": "Chapter", // Korean
            "中文": "章", // Chinese
            "اردو": "Chapter", // Urdu
            "Bahasa Melayu": "Bab", // Malay
            "Bahasa Indonesia": "Bab", // Indonesian
            "Tiếng Việt": "Chương", // Vietnamese
            "Slovenský": "Kapitola", // Slovak
        },
    });

    const FormattedText = ({ text, chapterIndex }) => {
        const chapters = useMemo(() => {
            let chapters = text.split("\n\n");
            for (let i = 0; i < chapters.length - 1; i++) {
                chapters[i] = chapters[i] + "\n" + chapters[i + 1];
                chapters.splice(i + 1, 1);
            }

            return chapters.map((chapter, index) => {
                const [title, ...points] = chapter.replace(/["']/g, "").split('\n').filter(Boolean);

                return {
                    title: `Chapter ${Math.max(index + 1, chapterIndex + 1)} - ${title}`,
                    points,
                };
            });
        }, [text]);

        return (
            <>
                {chapters.map((chapter, index) => (
                    <>
                        <h1 className={styles.chapterTitle} style={index === 0 ? { marginVertical: 0, marginBottom: 8 } : {}}>
                            {chapter.title.replace(/:/g, "").trim()}
                        </h1>
                        <div className={styles.bulletPointsContainer}>
                            {chapter.points.map((point, i) => (
                                <div className={styles.bulletPointContainer} key={i}>
                                    <h1 className={styles.bulletPoint}>•</h1>
                                    <h1 className={styles.bulletPointText}>{point.replace(/^-+/, '').trim()}</h1>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </>
        );
    };

    // const FormattedText = ({ text, chapterIndex }) => {
    //     const chapter = languageSelected ? translations["chapter"][languageSelected.language] : translations["chapter"]["English"];

    //     const chapters = useMemo(() => {
    //         if (text.indexOf(chapter) < 0 && text.indexOf("Chapter") < 0) {
    //             return [];
    //         }

    //         const chapters = text.indexOf(chapter) < 0 ? text.split("Chapter") : text.split(chapter);
    //         chapters.shift();

    //         return chapters.map((chapter, index) => {
    //             const [title, ...points] = chapter.replace(/["']/g, "").split('\n').filter(Boolean);

    //             return {
    //                 title: `Chapter ${chapterIndex + 1} - ${title}`,
    //                 points,
    //             };
    //         });
    //     }, [text]);

    //     return (
    //         <>
    //             {chapters.map((chapter, index) => (
    //                 <>
    //                     <h1 className={styles.chapterTitle} style={index === 0 ? { marginVertical: 0, marginBottom: 8 } : {}}>
    //                         {chapter.title.replace(/:/g, "").trim()}
    //                     </h1>
    //                     <div className={styles.bulletPointsContainer}>
    //                         {chapter.points.map((point, i) => (
    //                             <div className={styles.bulletPointContainer} key={i}>
    //                                 <h1 className={styles.bulletPoint}>•</h1>
    //                                 <h1 className={styles.bulletPointText}>{point.replace(/^-+/, '').trim()}</h1>
    //                             </div>
    //                         ))}
    //                     </div>
    //                 </>
    //             ))}
    //         </>
    //     );
    // };

    function getLastNonEmptyString(strings) {
        for (let i = strings.length - 1; i >= 0; i--) {
            if (strings[i]) {
                return strings[i];
            }
        }
        return "";
    }

    const handleSummary = async (e) => {
        e.preventDefault();
        if (loadingSummary) {
            e.stopPropagation();
            setLoadingSummary(true);
            return;
        }

        setLoadingSummary(true);

        const input = inputRef.current.value;
        const videoId = await extractVideoId(input);

        if (!videoId) {
            setStatusColor("rgba(255,0,70,1");
            setStatusText("Invalid URL");

            setTimeout(() => {
                setStatusColor("rgba(0,0,0,0)");
                setStatusText("");
            }, 3000);
            setLoadingSummary(false);
            return;
        }

        setStatusColor("rgba(255,200,0,1)");
        setStatusText("Loading Transcript...")

        const { transcript, videoDetails } = await getVideoDetails(videoId);
        const { duration, channel, title, thumbnail, status } = videoDetails;

        if (!transcript) {
            setStatusColor("rgba(255,0,70,1");
            setStatusText("Cannot summarize. Video has no captions.");
            setLoadingSummary(false);
            return;
        }

        setStatusColor("rgba(0,255,100,1");
        setStatusText("Fetched Transcript!");

        setToSummarizeYtAuthor(channel);
        setToSummarizeYtTitle(title);
        setToSummarizeYtThumbnail(thumbnail);
        setToSummarizeYtLength(duration);
        setToSummarizeYtId(videoId);
        setTranscript(transcript);
        setVideoLoaded(true);

        setMessagesDisplay([]);

        let chunks = await splitMessageIntoChunks(transcript, 10000);
        // chunks = chunks.filter((item, index) => item.length > 500 && index !== 0);
        chunks = chunks.map((chunk, index) => {
            // return translations["perMessagePrompt"][languageSelected.language] + chunk
            return prompt + chunk
        });

        for (let i = 0; i < chunks.length; i++) {
            if (stopRequested) {
                stopRequested = false;
                break;
            }
            const chunk = chunks[i];

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + auth,
                "accept": "text/event-stream",
            };

            const data = {
                model: model,
                messages: [
                    { "role": "system", "content": translations["system"][languageSelected.language] },
                    { "role": "user", "content": translations["system"][languageSelected.language] + "." + chunk },
                ],
                temperature: parseInt(temperature),
                stream: true,
            };

            const response = await fetch('https://api.openai.com/v1/chat/completions', { method: 'POST', headers: headers, body: JSON.stringify(data) });
            // const json = await response.json();
            // alert(JSON.stringify(json, null, 2));

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            let total = "";

            while (true) { // runs while the text stream isn't finished
                const { done, value } = await reader.read();
                if (done || stopRequested) { // conditions to check for when to stop
                    break;
                }

                // data filtration so that key data values can be extracted
                let string = decoder.decode(value).toString();
                let lines = string.split("\n\n");
                let data = getLastNonEmptyString(lines);
                let filtered = data.replace(/data: [DONE]: /g, "");
                filtered = filtered.replace(/data: /g, "");

                try {
                    const message_response = JSON.parse(filtered);
                    const response_message = message_response.choices[0].delta.content;
                    total += response_message;

                    let update = {
                        message: total,
                        from: "assistant",
                        message_id: generateFormattedString(),
                        hidden: false,
                    };

                    console.log(total);

                    setMessagesDisplay(prevMessages => {
                        let copy = [...prevMessages];
                        copy[i] = update;
                        return copy;
                    });
                } catch (error) {
                    // error handling here
                }
            }
        }

        setWriteSummary(true);
        setLoadingSummary(false);
    }

    const handleOpenAIKeysLink = (e) => {
        e.preventDefault();
        window.open("https://platform.openai.com/account/api-keys")
    }

    const handleSaveKey = (e) => {
        e.preventDefault();
        const value = keyRef.current.value;

        // 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
        if (!value.startsWith("sk-") || value.length < 30) {
            alert("Invalid key");
            return;
        }

        setAuth(value);
        localStorage.setItem("vidsummize_auth", value);
    }

    const resetApiKey = (e) => {
        e.preventDefault();
        localStorage.removeItem("vidsummize_auth");
        setAuth(false);
    }

    const toggleLanguageDropdown = (e) => {
        e.preventDefault();
        setLanguageDropdownOpen(!languageDropdownOpen);
    }

    const handleLanguageChange = (e, languageObject) => {
        e.preventDefault();
        setLanguageDropdownOpen(false);
        setLanguageSelected(languageObject);
        setPrompt(translations["perMessagePrompt"][languageObject.language]);
        promptRef.current.value = translations["perMessagePrompt"][languageObject.language];
        localStorage.setItem("vidsummize_language", JSON.stringify(languageObject));
    }

    const handleTempInput = (e) => {
        const value = e.target.value;
        if (value.length === 0) return;
        if (value > 1) return;
        if (value < 0) return;
        setTemperature(value);
    }

    const handleModelChange = (e) => {
        const value = e.target.value;
        setModel(value);
    }

    const handleStop = (e) => {
        e.preventDefault();
        stopRequested = true;
    }

    const handlePromptChange = (e) => {
        e.preventDefault();
        // alert(e.target.value);
        setPrompt(e.target.value);
    }

    return (
        <div className={styles.pageContainer}>
            <header>
                <div className={styles.logoContainer}>
                    <svg className={styles.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 32C0 14.3 14.3 0 32 0H64 320h32c17.7 0 32 14.3 32 32s-14.3 32-32 32V75c0 42.4-16.9 83.1-46.9 113.1L237.3 256l67.9 67.9c30 30 46.9 70.7 46.9 113.1v11c17.7 0 32 14.3 32 32s-14.3 32-32 32H320 64 32c-17.7 0-32-14.3-32-32s14.3-32 32-32V437c0-42.4 16.9-83.1 46.9-113.1L146.7 256 78.9 188.1C48.9 158.1 32 117.4 32 75V64C14.3 64 0 49.7 0 32zM96 64V75c0 25.5 10.1 49.9 28.1 67.9L192 210.7l67.9-67.9c18-18 28.1-42.4 28.1-67.9V64H96zm0 384H288V437c0-25.5-10.1-49.9-28.1-67.9L192 301.3l-67.9 67.9c-18 18-28.1 42.4-28.1 67.9v11z" /></svg>
                    <h1 className={styles.titleText}>VidSummize</h1>
                </div>
                <h1 className={styles.subTitleText}>VidSummize For The Web Beta</h1>
                <div className={styles.navBar}>
                    <button className={styles.resetApiKeyButton} onClick={resetApiKey}>Reset API Key</button>

                    <div className={styles.languageDropdownContainer}>
                        <button className={styles.languageButton} onClick={toggleLanguageDropdown}>{languageSelected.flag}</button>
                        <div className={styles.languageDropdown} style={languageDropdownOpen ? { display: "flex" } : { display: "none" }}>
                            {supportedLanguages.filter(item => item.supported).map((language, index) => {
                                return (
                                    <button onClick={(event) => { handleLanguageChange(event, language) }} className={styles.languageSelectButton}>
                                        {!language.limited ? <h1 className={styles.languageSelectText}>{language.flag + " " + language.language}</h1> : <h1 className={styles.languageSelectText} style={{ backgroundColor: "rgba(255,0,70,0.25)" }}>{language.flag + " " + language.language} {language.limitedText}</h1>}
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.themeContainer}>
                        {
                            theme === "dark" ?
                                <svg onClick={() => { setTheme("light"); setLightThemeRoot(); localStorage.setItem("vidsummize_theme", "light"); }} className={styles.themeLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z" /></svg>
                                :
                                <svg onClick={() => { setTheme("dark"); setDarkThemeRoot(); localStorage.setItem("vidsummize_theme", "dark"); }} className={styles.themeLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z" /></svg>
                        }
                    </div>
                    {/* <svg className={styles.navLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/></svg> */}
                    <svg onClick={() => { window.open("https://apps.apple.com/us/app/vidsummize-ai-video-summary/id1671335349") }} className={styles.navLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM127 384.5c-5.5 9.6-17.8 12.8-27.3 7.3-9.6-5.5-12.8-17.8-7.3-27.3l14.3-24.7c16.1-4.9 29.3-1.1 39.6 11.4L127 384.5zm138.9-53.9H84c-11 0-20-9-20-20s9-20 20-20h51l65.4-113.2-20.5-35.4c-5.5-9.6-2.2-21.8 7.3-27.3 9.6-5.5 21.8-2.2 27.3 7.3l8.9 15.4 8.9-15.4c5.5-9.6 17.8-12.8 27.3-7.3 9.6 5.5 12.8 17.8 7.3 27.3l-85.8 148.6h62.1c20.2 0 31.5 23.7 22.7 40zm98.1 0h-29l19.6 33.9c5.5 9.6 2.2 21.8-7.3 27.3-9.6 5.5-21.8 2.2-27.3-7.3-32.9-56.9-57.5-99.7-74-128.1-16.7-29-4.8-58 7.1-67.8 13.1 22.7 32.7 56.7 58.9 102h52c11 0 20 9 20 20 0 11.1-9 20-20 20z" /></svg>
                    <svg onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.summarizergpt") }} className={styles.navLogo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55" /></svg>
                </div>
            </header>
            <div className={styles.pageContent}>
                <div className={styles.privacyHeading}>END USER LICENSE AGREEMENT</div>
                <div className={styles.privacyPoint}>Last updated April 28, 2023</div>
                <div className={styles.privacyPoint}>VidSummize is licensed to You (End-User) by VidSummize ("Licensor"), for use only under the terms of this License Agreement.</div>
                <div className={styles.privacyPoint}>By downloading the Licensed Application from Apple's software distribution platform ("App Store") and Google's software distribution platform ("Play Store"), and any update thereto (as permitted by this License Agreement), You indicate that You agree to be bound by all of the terms and conditions of this License Agreement, and that You accept this License Agreement. App Store and Play Store are referred to in this License Agreement as "Services."</div>
                <div className={styles.privacyPoint}>The parties of this License Agreement acknowledge that the Services are not a Party to this License Agreement and are not bound by any provisions or obligations with regard to the Licensed Application, such as warranty, liability, maintenance and support thereof. VidSummize, not the Services, is solely responsible for the Licensed Application and the content thereof.</div>
                <div className={styles.privacyPoint}>This License Agreement may not provide for usage rules for the Licensed Application that are in conflict with the latest Apple Media Services Terms and Conditions and Google Play Terms of Service ("Usage Rules"). VidSummize acknowledges that it had the opportunity to review the Usage Rules and this License Agreement is not conflicting with them.</div>
                <div className={styles.privacyPoint}>VidSummize when purchased or downloaded through the Services, is licensed to You for use only under the terms of this License Agreement. The Licensor reserves all rights not expressly granted to You. VidSummize is to be used on devices that operate with Apple's operating systems ("iOS" and "Mac OS") or Google's operating system ("Android").</div>
                <div className={styles.privacyHeading}>TABLE OF CONTENTS</div>

                <div className={styles.privacyTitle}>1. THE APPLICATION</div>
                <div className={styles.privacyPoint}>VidSummize ("Licensed Application") is a piece of software created to generate summaries for videos — and customized for iOS and Android mobile devices ("Devices"). It is used to generate summaries for videos.</div>
                <div className={styles.privacyPoint}>The Licensed Application is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use this Licensed Application. You may not use the Licensed Application in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</div>

                <div className={styles.privacyTitle}>2. SCOPE OF LICENSE</div>
                <div className={styles.privacyPoint}>2.1 You are given a non-transferable, non-exclusive, non-sublicensable license to install and use the Licensed Application on any Devices that You (End-User) own or control and as permitted by the Usage Rules, with the exception that such Licensed Application may be accessed and used by other accounts associated with You (End-User, The Purchaser) via Family Sharing or volume purchasing.</div>
                <div className={styles.privacyPoint}>2.2 This license will also govern any updates of the Licensed Application provided by Licensor that replace, repair, and/or supplement the first Licensed Application, unless a separate license is provided for such update, in which case the terms of that new license will govern.</div>
                <div className={styles.privacyPoint}>2.3 You may not share or make the Licensed Application available to third parties (unless to the degree allowed by the Usage Rules, and with VidSummize's prior written consent), sell, rent, lend, lease or otherwise redistribute the Licensed Application.</div>
                <div className={styles.privacyPoint}>2.4 You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Licensed Application, or any part thereof (except with VidSummize's prior written consent).</div>
                <div className={styles.privacyPoint}>2.5 You may not copy (excluding when expressly authorized by this license and the Usage Rules) or alter the Licensed Application or portions thereof. You may create and store copies only on devices that You own or control for backup keeping under the terms of this license, the Usage Rules, and any other terms and conditions that apply to the device or software used. You may not remove any intellectual property notices. You acknowledge that no unauthorized third parties may gain access to these copies at any time. If you sell your Devices to a third party, you must remove the Licensed Application from the Devices before doing so.</div>
                <div className={styles.privacyPoint}>2.6 Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and damages.</div>
                <div className={styles.privacyPoint}>2.7 Licensor reserves the right to modify the terms and conditions of licensing.</div>
                <div className={styles.privacyPoint}>2.8 Nothing in this license should be interpreted to restrict third-party terms. When using the Licensed Application, You must ensure that You comply with applicable third-party terms and conditions.</div>

                <div className={styles.privacyHeading}>Technical Requirements</div>
                <div className={styles.privacyPoint}>Licensor attempts to keep the Licensed Application updated so that it complies with modified/new versions of the firmware and new hardware. You are not granted rights to claim such an update.</div>
                <div className={styles.privacyPoint}>You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Licensed Application satisfies the technical specifications mentioned above.</div>
                <div className={styles.privacyPoint}>Licensor reserves the right to modify the technical specifications as it sees appropriate at any time.</div>

                <div className={styles.privacyHeading}>No Maintenance or Support</div>
                <div className={styles.privacyPoint}>VidSummize is not obligated, expressed or implied, to provide any maintenance, technical or other support for the Licensed Application.</div>
                <div className={styles.privacyPoint}>VidSummize and the End-User acknowledge that the Services have no obligation whatsoever to furnish any maintenance and support services with respect to the Licensed Application.</div>

                <div className={styles.privacyHeading}>Use of Data</div>
                <div className={styles.privacyPoint}>You acknowledge that Licensor will be able to access and adjust Your downloaded Licensed Application content and Your personal information, and that Licensor's use of such material and information is subject to Your legal agreements with Licensor and Licensor's privacy policy: https://vidsummize.com/privacy-policy.</div>
                <div className={styles.privacyPoint}>You acknowledge that the Licensor may periodically collect and use technical data and related information about your device, system, and application software, and peripherals, offer product support, facilitate the software updates, and for purposes of providing other services to you (if any) related to the Licensed Application. Licensor may also use this information to improve its products or to provide services or technologies to you, as long as it is in a form that does not personally identify you.</div>
                <div className={styles.privacyHeading}>User-Generated Contributions</div>
                <div className={styles.privacyPoint}>The Licensed Application does not offer users to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or in the Licensed Application, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Licensed Application and through third-party websites or applications. As such, any Contributions you transmit may be treated in accordance with the Licensed Application Privacy Policy. When you create or make available any Contributions, you thereby represent and warrant that:</div>
                <ol className={styles.privacyPoint}>
                    <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                    <li>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Licensed Application, and other users of the Licensed Application to use your Contributions in any manner contemplated by the Licensed Application and this License Agreement.</li>
                    <li>You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness or each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Licensed Application and this License Agreement.</li>
                    <li>Your Contributions are not false, inaccurate, or misleading.</li>
                    <li>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>
                    <li>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</li>
                    <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
                    <li>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.</li>
                    <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
                    <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
                    <li>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</li>
                    <li>Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</li>
                    <li>Your Contributions do not otherwise violate, or link to material that violates, any provision of this License Agreement, or any applicable law or regulation.</li>
                </ol>
                <div className={styles.privacyPoint}>Any use of the Licensed Application in violation of the foregoing violates this License Agreement and may result in, among other things, termination or suspension of your rights to use the Licensed Application.</div>
                <div className={styles.privacyHeading}>7. CONTRIBUTION LICENSE</div>
                <div className={styles.privacyPoint}>You agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings).</div>
                <div className={styles.privacyPoint}>By submitting suggestions of other feedback regarding the Licensed Application, you agree that we can use and share such feedback for any purpose without compensation to you.</div>
                <div className={styles.privacyPoint}>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area in the Licensed Application. You are solely responsible for your Contributions to the Licensed Application and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</div>
                <div className={styles.privacyHeading}>8. LIABILITY</div>
                <div className={styles.privacyPoint}>8.1 Licensor's responsibility in the case of violation of obligations and tort shall be limited to intent and gross negligence. Only in case of a breach of essential contractual duties (cardinal obligations), Licensor shall also be liable in case of slight negligence. In any case, liability shall be limited to the foreseeable, contractually typical damages. The limitation mentioned above does not apply to injuries to life, limb, or health.</div>
                <div className={styles.privacyPoint}>8.2 Licensor takes no accountability or responsibility for any damages caused due to a breach of duties according to Section 2 of this License Agreement. To avoid data loss, You are required to make use of backup functions of the Licensed Application to the extent allowed by applicable third-party terms and conditions of use. You are aware that in case of alterations or manipulations of the Licensed Application, You will not have access to the Licensed Application.</div>
                <div className={styles.privacyHeading}>9. WARRANTY</div>
                <div className={styles.privacyPoint}>9.1 Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Licensor warrants that the Licensed Application works as described in the user documentation.</div>
                <div className={styles.privacyPoint}>9.2 No warranty is provided for the Licensed Application that is not executable on the device, that has been unauthorizedly modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of VidSummize's sphere of influence that affect the executability of the Licensed Application.</div>
                <div className={styles.privacyPoint}>9.3 You are required to inspect the Licensed Application immediately after installing it and notify VidSummize about issues discovered without delay by email provided in Contact Information. The defect report will be taken into consideration and further investigated if it has been emailed within a period of thirty (30) days after discovery.</div>
                <div className={styles.privacyPoint}>9.4 If we confirm that the Licensed Application is defective, VidSummize reserves a choice to remedy the situation either by means of solving the defect or substitute delivery.</div>
                <div className={styles.privacyPoint}>9.5 In the event of any failure of the Licensed Application to conform to any applicable warranty, You may notify the Services Store Operator, and Your Licensed Application purchase price will be refunded to You. To the maximum extent permitted by applicable law, the Services Store Operator will have no other warranty obligation whatsoever with respect to the Licensed Application, and any other losses, claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to any warranty.</div>
                <div className={styles.privacyPoint}>9.6 If the user is an entrepreneur, any claim based on faults expires after a statutory period of limitation amounting to twelve (12) months after the Licensed Application was made available to the user. The statutory periods of limitation given by law apply for users who are consumers.</div>
                <div className={styles.privacyHeading}>Product Claims</div>
                <div className={styles.privacyPoint}>
                    <span className={styles.listItem}>1.</span> VidSummize and the End-User acknowledge that VidSummize, and not the Services, is responsible for addressing any claims of the End-User or any third party relating to the Licensed Application or the End-User’s possession and/or use of that Licensed Application, including, but not limited to:
                    <ul className={styles.bulletList}>
                        <li>(i) product liability claims;</li>
                        <li>(ii) any claim that the Licensed Application fails to conform to any applicable legal or regulatory requirement; and</li>
                        <li>(iii) claims arising under consumer protection, privacy, or similar legislation, including in connection with Your Licensed Application’s use of the HealthKit and HomeKit.</li>
                    </ul>
                </div>

                <div className={styles.privacyHeading}>Legal Compliance</div>
                <div className={styles.privacyPoint}>You represent and warrant that You are not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a "terrorist supporting" country; and that You are not listed on any US Government list of prohibited or restricted parties.</div>

                <div className={styles.privacyHeading}>Contact Information</div>
                <div className={styles.privacyPoint}>
                    For general inquiries, complaints, questions or claims concerning the Licensed Application, please contact:
                    <br />
                    summarychatgpt@gmail.com
                </div>

                <div className={styles.privacyHeading}>Termination</div>
                <div className={styles.privacyPoint}>
                    The license is valid until terminated by VidSummize or by You. Your rights under this license will terminate automatically and without notice from VidSummize if You fail to adhere to any term(s) of this license. Upon License termination, You shall stop all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.
                </div>
                <div className={styles.privacyHeading}>Third-Party Terms of Agreements and Beneficiary</div>
                <div className={styles.privacyPoint}>
                    <span className={styles.bold}>VidSummize</span> represents and warrants that <span className={styles.bold}>VidSummize</span> will comply with applicable third-party terms of agreement when using Licensed Application.
                </div>
                <div className={styles.privacyPoint}>
                    In Accordance with Section 9 of the "Instructions for Minimum Terms of Developer's End-User License Agreement," both Apple and Google and their subsidiaries shall be third-party beneficiaries of this End User License Agreement and — upon Your acceptance of the terms and conditions of this License Agreement, both Apple and Google will have the right (and will be deemed to have accepted the right) to enforce this End User License Agreement against You as a third-party beneficiary thereof.
                </div>

                <div className={styles.privacyHeading}>Intellectual Property Rights</div>
                <div className={styles.privacyPoint}>
                    <span className={styles.bold}>VidSummize</span> and the End-User acknowledge that, in the event of any third-party claim that the Licensed Application or the End-User's possession and use of that Licensed Application infringes on the third party's intellectual property rights, <span className={styles.bold}>VidSummize</span>, and not the Services, will be solely responsible for the investigation, defense, settlement, and discharge or any such intellectual property infringement claims.
                </div>

                <div className={styles.privacyHeading}>Applicable Law</div>
                <div className={styles.privacyPoint}>
                    This License Agreement is governed by the laws of England and Wales, English law, excluding its conflicts of law rules.
                </div>

                <div className={styles.privacyHeading}>Miscellaneous</div>
                <div className={styles.privacyPoint}>
                    <span className={styles.bold}>17.1</span> If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose.
                </div>
                <div className={styles.privacyPoint}>
                    <span className={styles.bold}>17.2</span> Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.
                </div>

            </div>
        </div>
    )
}